import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";

interface IAppProps {

}

const App: React.FC<IAppProps> = (props: IAppProps) => {
	return (
		<BrowserRouter>
			<main>
				<Switch>

					<Route path="/" exact component={HomePage}/>

				</Switch>
			</main>
		</BrowserRouter>
	);
};

export default App;
