import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";
import BrowserWrap from "../../BrowserWrap/BrowserWrap";
import Fade from 'react-reveal/Fade';

const RealEstateSecuritiesPreview: React.FunctionComponent = (): JSX.Element => {

	return (
		<Container>
			<Row>
				<Col md={12} lg={5} className="mt-4 flex-column-justify-center">
					<Fade left>
						<h2>
							Real Estate Securities Exchange
						</h2>
						<p className="text-justify text-sm-left">
							Real Estate Securities Exchange provides the platform where private individuals can buy and sell real estate derivatives (options) to hedge against adverse changes in the future value of residential real estate assets, such as apartments and houses.
						</p>
						<ul>
							<li>Real time trading with other traders.</li>
							<li>Securities from all major regions of the Greater Vancouver Area.</li>
							<li>Hedges both against the increase and the decrease in future value of real estate assets.</li>
						</ul>

						<div className="mt-2">
							<a href="https://realestatesecurities.ca/#/" target="_blank">
								<button className="draw-border">
									See the Demo
								</button>
							</a>
						</div>
					</Fade>
				</Col>

				<Col md={12} lg={7} className="mt-4">
					<a href="https://realestatesecurities.ca/#/" target="_blank">
						<Fade right>
							<BrowserWrap className="hover-grow-tiny">
								<img
									src={process.env.PUBLIC_URL + "images/project-screenshots/real-estate/real-estate-securities-1.png"}
									alt="real-estate-securities-exchange"
								/>
							</BrowserWrap>
						</Fade>
					</a>
				</Col>
			</Row>
		</Container>
	);
};

export default RealEstateSecuritiesPreview;
