import * as React from "react";
import {ReactNode} from "react";
import "./ScalableBackground.css";

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export interface ISectionProperty {
	color: string,
}

interface IProps extends DivProps {
	children: ReactNode
	sectionProperties: ISectionProperty[]
}

const possibleWaves: string[] = [
	"M0.00,49.98 C150.16,150.00 349.60,-49.98 500.57,49.98 L500.57,150.00 L0.00,150.00 Z",
	"M0.00,49.98 C85.40,87.65 221.14,-27.79 500.57,49.98 L500.57,150.00 L0.00,150.00 Z",
	"M0.00,49.98 C268.66,126.15 451.35,9.71 500.57,49.98 L500.57,150.00 L0.00,150.00 Z",
	"M0.00,49.98 C160.06,-53.44 331.45,102.46 500.57,49.98 L500.57,150.00 L0.00,150.00 Z",
];
const possibleWavesLength = possibleWaves.length;

const ScalableBackground: React.FC<IProps> = (props: IProps): JSX.Element => {

	const {children, sectionProperties} = props;
	const sectionPropertiesLength = sectionProperties.length;

	function createSection(element: ReactNode, i: number) {
		const index = Math.floor(i % sectionPropertiesLength);
		const prevIndex = index === 0 ? sectionPropertiesLength - 1 : index - 1;
		const waveIndex = Math.floor(i % possibleWavesLength);
		const d = possibleWaves[waveIndex];
		const sectionProperty = sectionProperties[index];
		const lastColor = sectionProperties[prevIndex].color;
		const {color} = sectionProperty;
		return (
			<div className="sectionWrapper" style={{backgroundColor: lastColor}}>
				{i !== 0 &&
					<div className="waveContainer">
						<svg
							className="waveSVG"
							viewBox="0 0 500 150"
							preserveAspectRatio="none"
						>
							{/*https://smooth.ie/blogs/news/svg-wavey-transitions-between-sections*/}
							<path
								className="wavePath"
								d={d}
								style={{fill: color}}
							/>
						</svg>
					</div>
				}
				<section className="content" style={{backgroundColor: color}}>
					{element}
				</section>
			</div>
		)
	}

	return (
		// to prevent double adding children
		<div {...{...props, children: undefined}}>
			{React.Children.map(children, createSection)}
		</div>
	);
};

export default ScalableBackground;
