import * as React from "react";
import ScalableBackground, {ISectionProperty} from "../../components/scalable_background/ScalableBackground";
import RealEstateSecuritiesPreview
	from "../../components/HomePageContent/RealEstateSecuritiesPreview/RealEstateSecuritiesPreview";
import LazyHero from 'react-lazy-hero';
import LogoShowcase from "../../components/HomePageContent/LogoShowcase/LogoShowcase";
import Fade from "react-reveal/Fade"
import ThePeople from "../../components/HomePageContent/ThePeople/ThePeople";
import {StickyContainer, Sticky} from "react-sticky";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import {Col, Row} from "react-bootstrap";
import "./HomePage.scss";


interface IHomePageProps {

}

const sectionListProperties: ISectionProperty[] = [
	{color: "#FDFDFD"},
	{color: "#F9F8F9"},
	// {color: "rgba(203, 219, 213, 1)"},
];

const HomePage: React.FunctionComponent<IHomePageProps> = (props: IHomePageProps): JSX.Element => {
	return (

		<React.Fragment>
			<LazyHero
				imageSrc={process.env.PUBLIC_URL + "/images/bowen_island.jpg"}
				parallaxOffset={100}
				transitionDuration={0}
				color="#5d777bcc"
				minHeight="100vh"
			>
				<Fade>
					<Row className="d-flex justify-content-center hero-image-logo-spacer">
						<Col md={6} sm={8} xs={10}>
							<img src={process.env.PUBLIC_URL + "/images/logos/majic-software-white.png"} alt="majic-software-logo" style={{maxHeight: 240}}/>
						</Col>
					</Row>
				</Fade>
			</LazyHero>

			<StickyContainer>

				<Sticky>
					{(props) => (
						<div style={{...props.style, zIndex: 99999}}>
							<StickyHeader/>
						</div>
					)}
				</Sticky>

				<ScalableBackground sectionProperties={sectionListProperties}>
					<RealEstateSecuritiesPreview/>

					<LogoShowcase/>

					<ThePeople/>
				</ScalableBackground>
			</StickyContainer>
		</React.Fragment>
	)
};

export default HomePage;
