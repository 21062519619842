import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./StickyHeader.scss";
import {MdMailOutline, MdPhone} from "react-icons/md";
import Fade from "react-reveal/Fade"

interface IStickyHeaderProps {

}

const StickyHeader: React.FunctionComponent<IStickyHeaderProps> = (props: IStickyHeaderProps): JSX.Element => {

	return (
		<nav className="header py-1 py-sm-2">
			<Container>
				<Row>
					<Col xs={5} className="d-sm-none d-flex flex-column justify-content-center">
						<Fade left>
							<img
								src={process.env.PUBLIC_URL + "/images/logos/lotus.png"}
								alt="majic-software"
								className="header-image object-fit-contain"
							/>
						</Fade>
					</Col>

					<Col xs={0} sm={6}
					     className="d-none d-sm-flex flex-column justify-content-center align-items-sm-start">
						<Fade left>
							<img
								src={process.env.PUBLIC_URL + "/images/logos/majic-software.png"}
								alt="majic-software"
								className="header-image object-fit-contain"
							/>
						</Fade>
					</Col>

					<Col xs={7} sm={6} className="d-flex flex-column justify-content-center align-items-sm-end">
						<Fade right>
							<div className="d-flex flex-column flex-lg-row">
								<a href="tel:6043587349" className="text-decoration-none">
									<div
										className="d-flex flex-row align-items-center contact-left-item-spacing-helper header-contact-button">
										<MdPhone size="1.3em" className="ml-3 mr-2"/>
										<p className="mb-0 d-none d-sm-inline-block">
											(604) 358-7349
										</p>
										<p className="mb-0 d-inline-block d-sm-none">
											Phone
										</p>
									</div>
								</a>

								<a href="mailto:info@softwaremajic.com?subject=Majic Software" target="_blank"
								   className="text-decoration-none">
									<div className="d-flex flex-row align-items-center header-contact-button">
										<MdMailOutline size="1.3em" className="ml-3 mr-2"/>
										<p className="mb-0 d-none d-sm-inline-block">
											info@softwaremajic.com
										</p>
										<p className="mb-0 d-inline-block d-sm-none">
											Email
										</p>
									</div>
								</a>
							</div>
						</Fade>
					</Col>
				</Row>
			</Container>
		</nav>
	);
};

export default StickyHeader;
