import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";
import BrowserWrap from "../../BrowserWrap/BrowserWrap";
import Fade from 'react-reveal/Fade';

const ThePeople: React.FunctionComponent = (): JSX.Element => {

	return (
		<Container>
			<Fade bottom>
				<h1>
					The People
				</h1>
				<p className="text-justify text-sm-left">
					Majic Software Associates started in 1993 as a partnership between Momo Deretic,
					a lecturer at the Sauder School of Business, UBC, and Jasmina Arifovic,
					a professor of economics at Simon Fraser University.
				</p>
			</Fade>

			<Row>
				<Col xs={12} sm={4} className="mt-4">
					<Fade left>
						<BrowserWrap className="hover-grow-tiny">
							<img
								src={process.env.PUBLIC_URL + "/images/temp/momo.jpg"}
								alt="momo-deretic"
								className="w-100"
							/>
						</BrowserWrap>
					</Fade>
				</Col>

				<Col xs={12} sm={8} className="mt-4">
					<Fade right>
						<h2>
							Momo Deretic
						</h2>
						{/*<p>*/}
							{/*Credentials / Title Here*/}
						{/*</p>*/}

						<p className="text-justify">
							Momo holds a PhD in economics from the University of Marseille-Aix  in France and an MBA from McGill University in Montreal, Canada. His research deals with behavioral biases that affect decision making in market­ based prediction models. Momo's experience includes work in international banking in London, UK in the early eighties. Afterwards, he was Vice President of finance of a Chicago­ based US subsidiary of a large manufacturing company. He moved to Vancouver in 1993.
						</p>
					</Fade>
				</Col>
			</Row>

			<Row>
				<Col xs={12} sm={4} className="mt-4 order-1 order-sm-12">
					<Fade right>
						<BrowserWrap className="hover-grow-tiny">
							<img
								src={process.env.PUBLIC_URL + "/images/temp/jasmina_arifovic.jpg"}
								alt="momo-deretic"
								className="w-100"
							/>
						</BrowserWrap>
					</Fade>
				</Col>

				<Col xs={12} sm={8} className="mt-4 order-12 order-sm-1">
					<Fade left>
						<h2 className="text-sm-right">
							Jasmina Arifovic
						</h2>
						{/*<p  className="text-sm-right">*/}
							{/*Credentials / Title Here*/}
						{/*</p>*/}

						<p className="text-justify">
							Jasmina holds an MA and a PhD in economics from The University of Chicago. She is one of the most prominent Canadian economists in the field of computational and experimental economics. She was one of the first researchers to start using machine learning algorithms, such as genetic algorithm and neural networks, in her work in the late eighties. Her fields of research include, but are not limited to: monetary and trading mechanisms, currency crises, exchange rate dynamics, laboratory experiments, bank runs, models of learning in various trading institutions, and mechanisms for providing public goods. She regularly publishes papers in top journals and gives talks at conferences  and  workshops  all  over the world.
						</p>
					</Fade>
				</Col>
			</Row>
		</Container>
	)
};

export default ThePeople;
