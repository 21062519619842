import * as React from "react";
import "./BrowserWrap.scss";
import {ReactNode} from "react";

interface IBrowserWrapProps {
	children?: ReactNode;
	className?: string;
}

const BrowserWrap: React.FunctionComponent<IBrowserWrapProps> = (props: IBrowserWrapProps): JSX.Element => {

	const {children, className} = props;

	return (
		<div className={"outer-wrap" + (className ? " " + className : "")}>
			<div className="buttons-section-container flex-row">
				<div className="black-circle-button black-circle-button-spacer-left"/>
				<div className="black-circle-button black-circle-button-spacer-left"/>
				<div className="black-circle-button black-circle-button-spacer-left"/>
			</div>

			<div className="inner-wrap">
				{children}
			</div>
		</div>
	)
};

BrowserWrap.defaultProps = {
	className: "",
};

export default BrowserWrap;
