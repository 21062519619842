import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./LogoShowcase.scss";
import Fade from 'react-reveal/Fade';

const LogoShowcase: React.FunctionComponent = (): JSX.Element => {

	return (
		<Container>
			<Row>
				<Col sm={12} md={6} className="mt-4 flex-column-align-center">
					<Fade bottom>
					<img
						src={process.env.PUBLIC_URL + "images/logos/be-your-own-broker-2.png"}
						alt="be-your-own-broker-logo"
						className="logo-showcase-logos hover-grow-small"
					/>
					</Fade>

					<Fade left>
					<h2 className="text-center mt-5">
						Be Your Own Broker
					</h2>
					<p className="logo-showcase-description">
						Developed in 1995, "Be Your Own Broker 1.0" was a portfolio optimization software platform aimed primarily at private individuals with stock holdings. It relied on the analysis of historical stock returns to make recommendations for stock picks based on user preferences and then employing optimization techniques to minimize the variance of the selected portfolio for a given return. "Be your own broker 2.0" was developed in 2004 and support was discontinued after 2007. The major market for the platform were students at colleges and universities in BC who used the platform in their portfolio management classes.
					</p>
					</Fade>
				</Col>

				<Col sm={12} md={6} className="mt-4 flex-column-align-center">
					<Fade bottom>
					<img
						src={process.env.PUBLIC_URL + "images/logos/crash-lister-logo-2.png"}
						alt="crash-lister-logo"
						className="logo-showcase-logos hover-grow-small"
					/>
					</Fade>

					<Fade right>
					<h2 className="text-center mt-0 mt-md-5">
						Crashlister
					</h2>
					<p className="logo-showcase-description">
						Crashlister is dual-purpose platform that provides the valuations of residential real estate based on proprietary technology and then offers customized recommendations for users based on their personal preferences. The product is targeted towards real estate agents and private individuals who wish to buy or sell real estate properties on their own. The product is still in development.
					</p>
					</Fade>
				</Col>
			</Row>
		</Container>
	);
};

export default LogoShowcase;
